export default {
    handleErrosVacina(errorData, errorForm) {
        let error = errorData.errors;

        errorForm.nomeTecnico = error.nome_tecnico ? error.nome_tecnico[0] : '';
        errorForm.nomePopular = error.nome_popular ? error.nome_popular[0] : '';
        errorForm.descricao = error.descricao ? error.descricao[0] : '';
        errorForm.contraindicacao = error.contraindicacao ? error.contraindicacao[0] : '';
        errorForm.efeitoColateral = error.efeito_colateral ? error.efeito_colateral[0] : '';
        errorForm.cuidados = error.cuidados ? error.cuidados[0] : '';
        errorForm.idTipoAplicacaoSelecionado = error.id_tipo_aplicacao ? error.id_tipo_aplicacao[0] : ''; 
        errorForm.doencasSelecionadas = error.ids_doencas ? error.ids_doencas[0] : '';
        errorForm.error = '';
        
        if(typeof error == 'string') {
            errorForm.error = error;
        } 
    }
}
<template>
    <validation-provider
        #default="{ errors }"
        rules="required"
    >
        <b-form-group 
            :state="errors.length > 0 || errorApi ? false:null"
            label="Doenças relacionadas*"
            label-for="select-doencas-input"
            label-class="label_size_vacina"
        >
            <v-select
              id="select-doencas-input"
              v-model="DoencaSelecionada"
              @input="SelecionaDoenca"
              multiple
              multiselect
              variant="custom"
              item-text="nome_tecnico"
              item-value="id_doenca"
              label="nome_tecnico"
              placeholder="Selecione as doenças relacionadas a essa vacina"
              :options="Doencas"
              :disabled="Disabled"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
            <small class="text-muted">
                Mais de uma opção pode ser selecionada.
            </small>
            <br>
            <small v-if="errors[0]" class="text-danger">
                Este campo é de preenchimento obrigatório.
            </small>
            <small v-if="errorApi" class="text-danger">
                {{ errorApi }}
            </small>
        </b-form-group>
    </validation-provider>
</template> 

<script>
import vSelect from "vue-select";
import { ValidationProvider } from 'vee-validate'
import { BFormGroup } from 'bootstrap-vue'

export default {
  components: {
    vSelect, ValidationProvider,  BFormGroup
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Array,
      default: [],
    },
    disabledProp: {
      type: Boolean,
      default: false,
    },
    errorApi: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      DoencaSelecionada: [],
      Doencas: [],
      Disabled: false
    };
  },

  created() {
    this.buscaDoencas();
  },

  methods: {
    SelecionaDoenca() {
      this.$emit("GetDoenca", this.DoencaSelecionada);
    },
    buscaDoencas() {
      this.$http.get(this.$api.saveAndGetDoenca(), {
        params: {
          ativo: true
        }
      })
      .then((res) => {
        if(res.data != ""){
          this.Doencas = res.data;
        }else{
          this.Doencas = [];
        }
      });
    }
  },

  watch: {
    values(value) {
      this.DoencaSelecionada = value;
    },
    disabledProp(value) {
      this.Disabled = value;
    },
  }

};
</script>
<style scoped>

</style>